import React, { useState } from 'react'
import BaseHeader from './BaseHeader'
import pest from '../svg-icons/pest.svg';
import PostModal from './PestModal';
import PestPage from './Pest/PestPage';

function AgriHome(props) {

    const [agriHome, setAgriHome] = useState(true);
    const [pestPage, setPestPage] = useState(false);

    const handlePest = () => {
        setAgriHome(!agriHome);
        setPestPage(!pestPage);
    }

    return (
        <>
            {
                agriHome ? (
                    <div className='min-w-full min-h-full place-items-center font-medium text-center font-Gotham'>
                        <BaseHeader
                            triggerFunction={props.triggerFunction}
                        />
                        <div className='grid place-items-center mt-48'>
                            <PostModal
                                icon = {pest}
                                name = "Pest Management"
                                triggerFunction = {handlePest}
                                color = "bg-pest"
                                border = "border-pest"
                                text = "text-pest"
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        {
                            pestPage ? (
                                <PestPage
                                    name = "Pest Management"
                                    triggerFunction = {handlePest}
                                    setLoggedIn = {props.setLoggedIn}
                                    setIsLoading = {props.setIsLoading}
                                />
                            ) : (
                                <> this page should never come up </>
                            )
                        }
                    </>
                )
            }
        </>
    )
}

export default AgriHome