import React from 'react'

function ImageSelectModal({index, setImage, image_data, setImageModal}) {
    const images = image_data.map(item => {
        return (
            <>
                {item.tag !== "" && <div onClick={() => (setImage(item.url), setImageModal(false))}>
                    <img className='rounded-lg' src={item.url} alt="image" />
                </div>}
            </>
        )
    })
    return (
        <div className="absolute z-10 inset-0 h-full w-full bg-gray-900 opacity-95 flex items-center justify-center shadow-lg" onClick={() => setImageModal(false)}>
                    <div className="bg-white p-10 rounded-lg w-4/6 h-3/6 overflow-y-scroll" onClick={(e) => e.stopPropagation()}>
                        <div className='grid grid-cols-3 gap-4 place-items-center'>
                            <div className='col-span-3 text-3xl text-gray-500 font-semibold'> Select Image {index} </div>
                            {images}
                        </div>
                    </div>
                </div>
    )
}

export default ImageSelectModal