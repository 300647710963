import React from 'react'

function DropDownMenu({data, setText, setImage, color, image, setIsLoading}) {

    const options = data.map(option => {
        return (
            <>
            {image !== option.url ? (
                <div className={`flex place-content-center w-full p-2 bg-white text-black hover:${color}`} onClick={() => {setText(option.tag); setImage(option.url); setIsLoading(true);}}>
                    {/* <div className='flex flex-col justify-center items-center p-4 w-2/3'>
                        {option.tag}
                    </div> */}
                    <div>
                        <img  src={option.url} alt={option.tag} />
                    </div>
                </div>
            ) : (
                <> </>
            )}
            </>
        )
    })
    return (
        <div className='absolute rounded-xl shadow-lg mt-2 w-1/3 h-2/4 overflow-y-scroll'>
            {options}
        </div>
    )
}

export default DropDownMenu