import React, { useState } from 'react'
import Button from './Button';
import Home from './Home';
import Login from './Login'
import NavBar from './NavBar';
import agri from '../svg-icons/agri.svg';
import health from '../svg-icons/health.svg';
import AgriHome from './AgriHome';

function BaseHome(props) {

    const [baseHome, setBaseHome] = useState(true);
    const [healthPage, setHealthPage] = useState(false);
    const [agriPage, setAgriPage] = useState(false);

    const handleHealth = () => {
        setHealthPage(!healthPage);
        setBaseHome(!baseHome);
    }

    const handleAgri = () => {
        setAgriPage(!agriPage);
        setBaseHome(!baseHome);
    }


    return (
        <>
            {
                props.loggedIn ? (
                    <>
                        {
                            baseHome ? (
                                <div>
                                    <NavBar />
                                    <div className='grid grid-cols-2 mt-48 gap-10 sm:grid-cols-1 lg:grid-cols-2 px-40'>
                                        <Button
                                            icon = {agri}
                                            name = "Agriculture"
                                            triggerFunction = {handleAgri}
                                            color = "bg-pest"
                                        />
                                        <Button
                                            icon = {health}
                                            name = "Health"
                                            triggerFunction = {handleHealth}
                                            color = "bg-health"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {
                                        healthPage ? (
                                            <Home
                                                triggerFunction={handleHealth}
                                                setIsLoading={props.setIsLoading}
                                                setLoggedIn={props.setLoggedIn}
                                                loggedIn={props.loggedIn}
                                            />
                                        ) : (
                                            <>
                                                {
                                                    agriPage ? (
                                                        <AgriHome
                                                            triggerFunction={handleAgri}
                                                            setLoggedIn = {props.setLoggedIn}
                                                            setIsLoading = {props.setIsLoading}
                                                        />
                                                    ) : (
                                                        <> can't be possible</>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                </>
                            )  
                        }
                    </>
                ) : (
                    <Login
                        setLoggedIn = {props.setLoggedIn}
                    />
                )
            }
        </>
    )
}

export default BaseHome