import React, { useEffect, useState } from 'react'
import Header from './Header'
import NavBar from './NavBar'
import {image_data, disease_data} from "../data/Dermatology.js"
import ImageBox from './ImageBox'
import defaultImage from "../svg-icons/default-image.svg"
import { getAccessToken } from './utils'

function Dermatology(props) {

  const [flag, setFlag] = useState([]);
  const [image, setImage] = useState(defaultImage);
  const [imageData, setImageData] = useState();
  const [footText, setFootText] = useState("No Image Selected");
  const [borderColor, setBorderColor] = useState("border-gray-300");
  const [footerBackgroudColor, setFooterBackgroundColor] = useState("bg-gray-300")
  const [text, setText] = useState("select a picture");
  const url = "https://demos.wadhwaniai.org/"

  const matrix = disease_data.map(disease => {
    return(
      <div className={`text-md text-center border-2 rounded p-4 ${ flag.includes(disease.code) ? 'bg-red-500 font-medium text-white' : 'bg-gray-200 font-light'}`}>
        {disease.Name}
      </div>
    )
  })

  // const solution = disease_data.map(disease => {
  //   return (
  //     <>
  //       {flag.includes(disease.code) && disease.code !== 'other' ? (
  //         <div className='grid grid-cols-3 gap-2 mt-2 pl-4'>
  //           <div className='row-span-3'>
  //             <div className='font-light text-xs text-gray-600'>Category</div>
  //             <div className='text-xs font-medium'>{disease.Category}</div>
  //           </div>
  //           <div>
  //             <div className='font-light text-xs text-gray-600'>Treatment Plan</div>
  //             <div className='text-xs font-medium'>{disease['Treatment Plan']}</div>
  //           </div>
  //           <div>
  //             <div className='font-light text-xs text-gray-600'>Restrictions/Exceptions</div>
  //             <div className='text-xs font-medium'>{disease['Restrictions/Exceptions']}</div>
  //           </div>
  //           <div>
  //             <div className='font-light text-xs text-gray-600'>Medication</div>
  //             <div className='text-xs font-medium'>{disease['Name of Medication']}</div>
  //           </div>
  //           <div>
  //             <div className='font-light text-xs text-gray-600'>Comments</div>
  //             <div className='text-xs font-medium'>{disease.Comments}</div>
  //           </div>
  //           <div>
  //             <div className='font-light text-xs text-gray-600'>Dosage</div>
  //             <div className='text-xs font-medium'>{disease.Dosage}</div>
  //           </div>
  //           <div>
  //             <div className='font-light text-xs text-gray-600'>Refer to Dermatologist</div>
  //             <div className='text-xs font-medium'>{disease['Refer to Dermatologist']}</div>
  //           </div>
  //         </div>
  //       ) : (
  //         <></>
  //       )}
  //     </>
  //   )
  // })

  const apiCall = async () => {
    // setFlag(arr => []);
    if (image === defaultImage) {
      return;
    }
    try {
      var access_token = getAccessToken();
    } catch (error) {
      console.log(error);
      props.setLoggedIn(false);
    }

    var formdata = new FormData();
    formdata.append("tag", text);
    formdata.append("project", "DERMATOLOGY");
    formdata.append("url", image);

    const requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        'token': `${access_token}`
      },
      redirect: 'follow'
    };
    const response = await fetch(`${url}/api/uploaded`, requestOptions);
    const data = await response.json();

    if(!response.ok) {
      console.log("got an error!!!!");
      console.log(response);
      props.setLoggedIn(false);
    } else {
      console.log(data);
      if(data['predicted_class_name']==='normal') {
        setFootText("No Disease detected");
        setBorderColor("border-green-500");
        setFooterBackgroundColor("bg-green-500");
        setFlag(arr => []);
      } else {
        setFlag( arr => [data['predicted_class_name']]);
        setFootText("Disease detected");
        setBorderColor("border-red-500");
        setFooterBackgroundColor("bg-red-500");
      }

    }
    props.setIsLoading(false);
  }


  const getImages = async () => {
    try {
      var access_token = getAccessToken();
    } catch (error) {
      console.log(error);
      props.setLoggedIn(false);
    }

    const requestOptions = {
      method: "GET",
      headers: {
        'token': `${access_token}`
      },
      redirect: 'follow'
    };
    const response = await fetch(`${url}/api/get_urls?project=DERMATOLOGY`, requestOptions);
    const data = await response.json();
    if(!response.ok) {
      console.log("got an error");
      props.setLoggedIn(false);
    }
    else {
      console.log(data);
      setImageData(data);
    }
  }

  useEffect(() => {
    setFootText("No Image Selected");
    setBorderColor("border-gray-300");
    setFooterBackgroundColor("bg-gray-300");
    setFlag(arr => []);
    apiCall();
  }, [image])

  function handleClick() {
    setFootText("No Image Selected");
    setBorderColor("border-gray-300");
    setFooterBackgroundColor("bg-gray-300");
    setFlag(arr => []);
  }

  useEffect(() => {
    getImages();
  }, [])

  return (
    <div>
        <NavBar />
        <Header
            name = {props.name}
            triggerFunction = {props.triggerFunction}
            color = {"bg-yellow-100"}
            heading = "e-Health AI Unit"
        />
        <div className='grid grid-cols-5 px-16 gap-4'>
          <div className='col-span-2 h-full'>
            <ImageBox
              data={imageData}
              color = {"bg-yellow-100"}
              setImage={setImage}
              footText={footText}
              borderColor={borderColor}
              footerBackgroudColor={footerBackgroudColor}
              image={image}
              text={text}
              setText={setText}
              setIsLoading = {props.setIsLoading}
              handleClick = {handleClick}
            />
          </div>
          <div className='col-span-3'>
            <div className='text-md font-light my-4'>
              An AI-based computer vision solution to detect 10 types of skin diseases.
            </div>
            <div className='grid grid-cols-3 bg-gray-100 shadow-md rounded gap-10 p-5 overflow-y-auto'>
                {matrix}
            </div>
          </div>
        </div>
    </div>
  )
}

export default Dermatology
