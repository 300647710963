import React, { useEffect, useState } from 'react'
import Header from '../Header'
import ImageBox from '../ImageBox'
import NavBar from '../NavBar'
import defaultImage from "../../svg-icons/default-image.svg"
import { getAccessToken } from '../utils'
import ImageSection from './ImageSection'
import pre_report from "../../svg-icons/pre-report.svg"
import image_error from "../../svg-icons/image-error.svg"
import alert from "../../svg-icons/alert.svg"
import insect from "../../svg-icons/insect.svg"
import sparay from "../../svg-icons/spray.svg"

function PestPage(props) {

    const [image1, setImage1] = useState(defaultImage);
    const [image2, setImage2] = useState(defaultImage);
    const [imageOneState, setImageOneState] = useState("normal");
    const [imageTwoState, setImageTwoState] = useState("normal");
    const [responseBody, setResponseBody] = useState("normal");
    const [imageData, setImageData] = useState(null);
    const [alertLevel, setAlertLevel] = useState("");
    const [pesticideComponent, setPesticideComponent] = useState(null);
    const url = "https://demos.wadhwaniai.org/"


    const fillPesticideData = (pesticideData=[]) => {
        const data = pesticideData.map(item => {
            return (
                <div className='flex p-3 border-b-2'>
                    <img className='bg-gray-200 py-6 px-7 rounded-3xl' src={sparay}  alt='spray'/>
                    <div className='grid gap-2 p-2'>
                        <a className='text-lg font-medium text-gray-700'>{item.title}</a>
                        <a className='text-sm font-normal text-gray-500'>{item.dosage}</a>
                    </div>
                </div>
            )
        })
        setPesticideComponent(data);
    }
    const apiCall = async () => {
        if(image1 === defaultImage || image2===defaultImage) {
            return;
        }
        try {
            var access_token = getAccessToken();
        } catch (error) {
            console.log(error);
            props.setLoggedIn(false);
        }

        var formdata = new FormData();
        formdata.append("project", "PM");
        formdata.append("images",JSON.stringify([
            {
              "url": image1,
              "tag": "image1.png"
            },
            {
              "url": image2,
              "tag": "image2.png"
            }
        ]));

        const requestOptions = {
            method: "POST",
            body: formdata,
            headers: {
              'token': `${access_token}`
            },
            redirect: 'follow'
        };

        props.setIsLoading(true);
        const response = await fetch(`${url}/api/uploaded`, requestOptions);
        var data = await response.json();
        data = data?.data;
        console.log(response, data);
        
        if('error' in data) {
            setResponseBody("error");
            if(data['error'] === 'Both images are rejected!!') {
                setImageOneState('fail');
                setImageTwoState('fail');
            } else if(data['error']==='Image1 is rejected!!'){
                setImageOneState('fail');
                setImageTwoState('pass');
            } else {
                setImageTwoState('fail');
                setImageOneState('pass');
            }
        } else {
            setResponseBody('pass');
            setImageOneState('pass');
            setImageTwoState('pass');
            setAlertLevel(data['colour']);
            // setPesticideData(data['pesticides']);
            if(data['pesticides']?.length === 0){ 
                fillPesticideData([{title: "None", dosage: "----"},{title: "None", dosage: "----"}]);
            } else {
                fillPesticideData(data['pesticides']);
            }
        }
        props.setIsLoading(false);
    }
    const getImages = async () => {
        try {
          var access_token = getAccessToken();
        } catch (error) {
          console.log(error);
          props.setLoggedIn(false);
        }
    
        const requestOptions = {
          method: "GET",
          headers: {
            'token': `${access_token}`
          },
          redirect: 'follow'
        };
        const response = await fetch(`${url}/api/get_urls?project=PM`, requestOptions);
        const data = await response.json();
        if(!response.ok) {
          console.log("got an error");
          props.setLoggedIn(false);
        }
        else {
          console.log(data);
          setImageData(data);
        }
    }
    

    useEffect(() => {
        console.log("this is being called");
        getImages();
    }, [])

    useEffect(() => {
        console.log("api call done");
        apiCall();
    }, [image1, image2]);
    
    return (
        <div>
            <NavBar />
            <Header
                name = {props.name}
                triggerFunction = {props.triggerFunction}
                color={"bg-green-100"}
                heading = "Agri AI Unit"
            />
            <div className='grid grid-cols-4 px-16 gap-2'>
                <div className='col-span-1 h-full rounded-lg'>
                    <div className='py-4 px-2 text-md font-light'>Select Image </div>
                    <div className='grid grid-cols-1 gap-4 p-2 bg-gray-100 rounded-lg'>
                        
                        <ImageSection
                            index={1}
                            image={image1}
                            setImage={setImage1}
                            imageState={imageOneState}
                            image_data={imageData}
                        />
                        <ImageSection
                            index={2}
                            image={image2}
                            setImage={setImage2}
                            imageState={imageTwoState}
                            image_data={imageData}
                        />
                    </div>
                </div>
                <div className='col-span-3 h-full'>
                    <div className='text-md font-light my-4'>
                        An AI-based infestation detection tool detect 2 type of pest pink bollworm & american bollworm.
                    </div>
                    <div className='grid grid-cols-3 bg-gray-100 shadow-md rounded gap-5 p-4 mt-4 pb-5'>
                        {responseBody==='normal' && (
                            <div className='col-span-3 grid place-items-center'>
                                <img className='mt-8' src={pre_report} alt='pre-report'/>
                                <p className='text-4xl font-semibold mt-10 text-gray-500'>No Report</p>
                                <p className='text-2xl font-light m-3 pb-10 text-gray-500'>Upload both the images to get report </p>
                            </div>
                        )}
                        {responseBody==='error' && (
                            <div className='col-span-3 grid place-items-center'>
                                <img className='p-9' src={image_error} alt="image error"/>
                                <div className='text-4xl font-normal text-gray-500 p-10'>
                                {(imageOneState==='fail' && imageTwoState==='fail') ? (
                                    <a className=''>Both the Image 1 and Image 2 Rejected</a>
                                ) : (
                                    <>
                                        {
                                            imageOneState==='fail' ? (
                                                <a>Image 1 Rejected</a>
                                            ) : (
                                                <a>Image 2 Rejected</a>
                                            )
                                        }
                                    </>
                                )}
                                </div>
                                <a className='text-3xl font-light text-gray-500 p-9 w-3/4 text-center'>Seems like you have uploaded incorrect image please upload the pest image only.</a>
                            </div>
                        )}
                        {responseBody==='pass' && (
                            <div className='col-span-3 grid grid-cols-5 gap-2 h-[480px]'>
                                {alertLevel === 'yellow' && 
                                <div className='col-span-2 bg-yellow-400 grid place-items-center px-2 py-10 rounded-md h-[480px]'>
                                    <img src={alert} alt='alert'/>
                                    <a className='m-4 text-4xl text-white font-semibold'>Mild Infestation</a>
                                </div>
                                }
                                {alertLevel === 'red' && 
                                <div className='col-span-2 bg-red-400 grid place-items-center px-2 py-10 rounded-md h-[480px]'>
                                    <img src={alert} alt='alert'/>
                                    <a className='m-4 text-4xl text-white font-semibold'>High Infestation</a>
                                </div>
                                }
                                {alertLevel === 'green' && 
                                <div className='col-span-2 bg-green-400 grid place-items-center px-2 py-10 rounded-md h-[480px]'>
                                    <img src={alert} alt='alert'/>
                                    <a className='m-4 text-4xl text-white font-semibold'>No Infestation</a>
                                </div>
                                }
                                <div className='col-span-3 bg-white p-4 rounded-lg h-[480px]'>
                                    <div className='flex text-2xl font-medium text-gray-700 p-2'>
                                        <img className='h-20' src={insect} alt='insect' />
                                        {alertLevel === 'green' ? (
                                            <p className='grid place-items-center p-2'>No Need to Spray pesticide.</p>
                                        ) : (
                                            <p className='grid place-items-center p-2'>Spray pesticide for Pink and American bollworm at the earliest.</p>
                                        )}
                                    </div>
                                    <div className='border-2 p-2 text-gray-500 rounded-xl text-sm my-4'> Do not spray pesticide if you have sprayed anytime in the last 15 days. Do not use the same chemicals more than once in the same season!</div>
                                    <div className='text-gray-700 font-medium text-xl my-4'> Suggested Pesticides </div>
                                    <div className=' overflow-scroll h-2/4'> {pesticideComponent}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PestPage;