import React, { useEffect, useState } from 'react'
import dropdown from "../svg-icons/dropdown.svg"
import DropDownMenu from './DropDownMenu';
import defaultImage from "../svg-icons/default-image.svg"

function ImageBox(props) {
    const [open, setOpen] = useState(false);
    // const [text, setText] = useState("select a picture");
    
    return (
        <div className='my-2'>
            {/* <div className='text-md font-normal my-1 text-gray-600'>Select a Picture</div> */}
            <div className='bg-black w-2/3 my-2 rounded' onClick={() => {setOpen(!open); props.handleClick();}}>
                <div className='flex flex-row justify-around items-center p-2'>
                    <div className='text-white text-md font-medium'>
                        Select a Picture
                    </div>
                    <div className=''>
                        <img src={dropdown} alt='drop down icon'/>
                    </div>
                </div>
                {open ? (
                    <DropDownMenu
                        data={props.data}
                        setText={props.setText}
                        setImage={props.setImage}
                        color={props.color}
                        image={props.image}
                        setIsLoading = {props.setIsLoading}
                    />
                ) : (
                    <></>
                )}
            </div>
            <div className={`border-4 h-full ${props.borderColor} rounded-lg ${props.footerBackgroudColor}`}>
                <div className={`w-full bg-gray-200 grid place-items-center rounded-t-lg`}>
                    <img className='h-[350px]' src={props.image} alt='main image'/>
                </div>
                <div className={`${props.footerBackgroudColor} p-2 text-lg font-medium text-white rounded-b-lg`}>
                    {props.footText}
                </div>
            </div>
        </div>
    )
}

export default ImageBox
