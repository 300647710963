import React from 'react'
import back from "../svg-icons/back-button.svg"

function Header(props) {
  return (
    <div className={`px-16 pb-1 pt-2 ${props.color}`}>
      <div className='flex w-full font-Gotham'>
        <div onClick={props.triggerFunction} className='cursor-pointer text-xl flex flex-col justify-center items-center'>
            <img src={back} alt='back'/>
            {/* &lt; */}
        </div>
        <div className='w-full relative text-center'>
            {/* {props.name} */}
            <div className='text-xl text-gray-500'>
                {props.heading}
            </div>
            <div className='text-2xl uppercase'>
              {props.name}
            </div>
        </div>
      </div>
    </div>
  )
}

export default Header
