import React, { useEffect, useState } from 'react'
import Header from './Header'
import ImageBox from './ImageBox'
import NavBar from './NavBar'
import {image_data, disease_data} from "../data/Retinopathy.js"
import defaultImage from "../svg-icons/default-image.svg"
import { getAccessToken } from './utils'

function Retinopathy(props) {

  const [flag, setFlag] = useState([]);
  const [image, setImage] = useState(defaultImage);
  const [imageData, setImageData] = useState();
  const [footText, setFootText] = useState("No Image Selected");
  const [borderColor, setBorderColor] = useState("border-gray-300");
  const [footerBackgroudColor, setFooterBackgroundColor] = useState("bg-gray-300")
  const [referable, setReferable] = useState(false);
  const [nonReferable, setNonReferable] = useState(false);
  const [text, setText] = useState("select a picture");
  const url = "https://demos.wadhwaniai.org/"

  const matrix = disease_data.map(disease => {
    return(
      <div className={`text-md text-center border-2 rounded px-2 py-3 ${ flag.includes(disease.code) ? (disease.code === "No Diabetic Retinopathy") ? 'bg-green-500 font-medium text-white' : 'bg-red-500 font-medium text-white' : 'bg-gray-200 font-light'}`}>
        {disease.level}
      </div>
    )
  })

  const apiCall = async () => {

    if (image === defaultImage) {
      return;
    }
    
    setReferable(false);
    setNonReferable(false);

    try {
      var access_token = getAccessToken();
    } catch (error) {
      console.log(error);
      props.setLoggedIn(false);
    }

    var formdata = new FormData();
    formdata.append("tag", text);
    formdata.append("project", "OPHTHA");
    formdata.append("url", image);

    const requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        'token': `${access_token}`
      },
      redirect: 'follow'
    };

    const response = await fetch(`${url}/api/uploaded`, requestOptions);
    const data = await response.json();

    if (!response.ok) {
      console.log("you got an error");
      console.log(response);
      props.setLoggedIn(false);
    } else {
      console.log(data);
      setFlag( arr => [data['predicted_class_name']]);
      if(data['predicted_class_name']==='No Diabetic Retinopathy') {
        setNonReferable(true);
        setFootText("Diabetic Retinopathy Undetected");
        setBorderColor("border-green-500");
        setFooterBackgroundColor("bg-green-500");
      } else {
        setReferable(true);
        setFootText("Diabetic Retinopathy Detected");
        setBorderColor("border-red-500");
        setFooterBackgroundColor("bg-red-500");
      }
    }
    props.setIsLoading(false);
  }

  const getImages = async () => {

    try {
      var access_token = getAccessToken();
    } catch (error) {
      console.log(error);
      props.setLoggedIn(false);
    }

    const requestOptions = {
      method: "GET",
      headers: {
        'token': `${access_token}`
      },
      redirect: 'follow'
    };
    const response = await fetch(`${url}/api/get_urls?project=OPHTHA`, requestOptions);
    const data = await response.json();
    if(!response.ok) {
      console.log("got an error");
      props.setLoggedIn(false);
    }
    else {
      console.log(data);
      setImageData(data);
    }
  }
  useEffect(() => {
    setFootText("No Image Selected");
    setBorderColor("border-gray-300");
    setFooterBackgroundColor("bg-gray-300");
    setFlag(arr => []);
    setNonReferable(false);
    setReferable(false);
    apiCall();
  }, [image])

  function handleClick() {
    setFootText("No Image Selected");
    setBorderColor("border-gray-300");
    setFooterBackgroundColor("bg-gray-300");
    setFlag(arr => []);
    setNonReferable(false);
    setReferable(false);
  }

  useEffect(() => {
    getImages();
  }, [])

  return (
    <div>
        <NavBar />
        <Header
            name = {props.name}
            triggerFunction = {props.triggerFunction}
            color = {"bg-blue-100"}
            heading = "e-Health AI Unit"
        />
        <div className='grid grid-cols-5 px-16 gap-4'>
            <div className='col-span-2 h-full'>
              <ImageBox
                data={imageData}
                color={"bg-blue-100"}
                setImage={setImage}
                footText={footText}
                borderColor={borderColor}
                footerBackgroudColor={footerBackgroudColor}
                image={image}
                text={text}
                setText={setText}
                setIsLoading = {props.setIsLoading}
                handleClick = {handleClick}
              />
            </div>
            <div className='col-span-3'>
              <div className='text-md font-light my-4'>
                An AI based solution to detect the presence of Diabetic Retinopathy (DR).
              </div>
              <div className='grid grid-cols-3 bg-gray-100 shadow-md rounded gap-6 p-3 overflow-y-auto'>
                  
                  <div className='col-span-1 grid gap-6'>
                    {matrix}
                  </div>
                  <div className='col-span-1 grid grid-rows-5 gap-6'>
                      <div className={`text-lg text-center border-2 rounded px-2 py-3 ${nonReferable ? 'bg-white text-green-600 border-green-500 font-medium' : 'bg-gray-200 font-light'}`}>
                        Non Referable
                      </div>
                      <div className={`text-lg text-center border-2 rounded px-2 py-3 ${referable ? 'bg-white text-red-600 border-red-500 font-medium' : 'bg-gray-200 font-light'}`}>
                        Referable
                      </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Retinopathy
