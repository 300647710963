import g20 from "../svg-icons/g-20.svg"

export let image_data = [
    {
        "id": 1,
        "tag": "Image_1",
        "url": g20
    },
    {
        "id": 2,
        "tag": "Image_2",
        "url": g20
    },
    {
        "id": 3,
        "tag": "Image_3",
        "url": g20
    }
    ,
    {
        "id": 4,
        "tag": "Image_3",
        "url": g20
    }
    ,
    {
        "id": 5,
        "tag": "Image_3",
        "url": g20
    }
]

export let disease_data = [
    {
        "id": 1,
        "code": "No Diabetic Retinopathy",
        "level": "No DR"
    },
    {
        "id": 2,
        "code": "Mild Diabetic Retinopathy",
        "level": "Mild DR"
    },
    {
        "id": 3,
        "code": "Moderate Diabetic Retinopathy",
        "level": "Moderate DR"
    },
    {
        "id": 4,
        "code": "Severe Diabetic Retinopathy",
        "level": "Severe DR"
    },
    {
        "id": 5,
        "code": "Proliferative Diabetic Retinopathy",
        "level": "Proliferative Retinopathy"
    }
]