import React from 'react'
import wadhwani from "../svg-icons/wadhwani.svg"
import back from "../svg-icons/back-button.svg"

function BaseHeader(props) {
  return (
    <div className='px-16 pb-1 pt-2'>
      <div className='flex w-full font-Gotham'>
        <div onClick={props.triggerFunction} className='cursor-pointer text-xl flex flex-col justify-center items-center'>
            <img src={back} alt='back'/>
            {/* &lt; */}
        </div>
        <div className='w-full relative flex justify-center px-16 pt-4 pb-4 sm:flex-row h-[150px]'>
            <img src={wadhwani} alt='wadhwani' />
        </div>
      </div>
    </div>
  )
}

export default BaseHeader
