import React, { useState, useEffect } from 'react';
import BaseHome from './components/BaseHome';
import Home from './components/Home';


function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    // setIsLoading(true);
    if (token) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  return (
    <div>
      {isLoading && (
        <div className="absolute z-10 top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-white border-8 border-white m-2">
          <div className="flex items-center justify-center ">
            <div className="w-40 h-40 border-t-4 border-l-4 border-dotted border-black rounded-full animate-spin"></div>
          </div>
          <div className='text-center text-4xl mt-4'>
            Computing Result
          </div>
        </div>
      )}
      <div>
        <BaseHome
          setIsLoading={setIsLoading}
          setLoggedIn={setLoggedIn}
          loggedIn={loggedIn}
        />
      </div>
    </div>
  );
}

export default App;
