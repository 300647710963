export function getAccessToken() {
    try {
        const token = sessionStorage.getItem("token");
        if (!token) {
            throw new Error("No token found in session storage");
        }
        return token;
    } catch (error) {
        throw error;
    }
}