import React from 'react'
import { getAccessToken } from './utils'

function CDSS(props) {

    function Open() {
        console.log("called")
        try {
            var access_token = getAccessToken();
          } catch (error) {
            console.log(error);
            props.setLoggedIn(false);
          }

        // const url = `https://cdss-g20.wadhwaniai.org/?token=${access_token}`;
        const url = 'https://cdss-g20.wadhwaniai.org/?ref_id=testest&lang=1&token=953d3b79501d60495a5b0cd5ece7ba49&srcTyp=HWC&dob=30-08-1999&gendercode=1';
	window.open(url, "_blank");
    }

    return (
        <div className='flex cursor-pointer text-2xl text-gray-600 m-1 rounded shadow-lg' onClick={()=> Open()}>
            <div className={`${props.color} w-1/4 p-4 flex justify-center rounded`} >
                <img  src={props.icon} alt={props.color}/>
            </div>
            <div className='w-2/3 grid place-items-center pt-4 pl-4'>
                {props.name}
            </div>
        </div>
    )
}

export default CDSS
