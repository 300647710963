import video1 from "../svg-icons/video1.svg"
import video2 from "../svg-icons/video2.svg"
import video3 from "../svg-icons/video3.svg"
import video4 from "../svg-icons/video4.svg"
import pest from "../svg-icons/pest-example.svg"

export let video_data = [
    {
        "index": 1,
        "title": "Why should we use CottonAce?",
        "url": "https://drive.google.com/file/d/1uTkNSKM2VJ2WwjCcvSRcQipCcbEaJRBm/view?usp=share_link",
        "image": video1
    },
    {
        "index": 2,
        "title": "How do you monitor?",
        "url": "https://drive.google.com/file/d/13OMYNQq2Kn0XPo1b84WPMDT3flg47pUJ/view?usp=share_link",
        "image": video2
    },
    {
        "index": 3,
        "title": "Walkthrough",
        "url": "https://drive.google.com/file/d/1mf1OJVbPn4uxlXNTP9VNI6fVCNhtyngV/view?usp=share_link",
        "image": video3
    },
    {
        "index": 4,
        "title": "Concept",
        "url": "https://drive.google.com/file/d/1AcOK31nqUwf8tKUtRYvl5sIhLh-LBjla/view?usp=sharing",
        "image": video4
    }
]

export let image_data = [
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    },
    {
        "url": pest
    }
]