import React, { useEffect, useState } from 'react'
import Header from './Header'
import ImageBox from './ImageBox'
import NavBar from './NavBar'
import {disease_data} from "../data/Teleradiology.js"
import defaultImage from "../svg-icons/default-image.svg"
import { getAccessToken } from './utils'

function Teleradiology(props) {
  
  const [xRayFlag, setXRayFlag] = useState([]);
  const [image, setImage] = useState(defaultImage);
  const [imageData, setImageData] = useState();
  const [footText, setFootText] = useState("No Image Selected");
  const [borderColor, setBorderColor] = useState("border-gray-300");
  const [footerBackgroudColor, setFooterBackgroundColor] = useState("bg-gray-300")
  const [text, setText] = useState("select a picture");
  const url = "https://demos.wadhwaniai.org/"

  const matrix = disease_data.map(disease => {
    return(
      <div className={`text-xs text-center border-2 rounded px-2 py-3 ${ xRayFlag.includes(disease.code) ? 'bg-red-500 font-medium text-white' : 'bg-gray-200 font-light'}`}>
        {disease.name}
      </div>
    )
  })

  const getResult = async () => {
    if (image === defaultImage) {
      return;
    }
    
    try {
      var access_token = getAccessToken();
    } catch (error) {
      console.log(error);
      props.setLoggedIn(false);
    }
    var formdata = new FormData();
    console.log("majorrr", image);
    formdata.append("tag", text);
    formdata.append("project", "TELERADIOLOGY");
    formdata.append("url", image);
    const requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        'token': `${access_token}`
      },
      redirect: 'follow'
    };
    // console.log(UserName)
    const response = await fetch(`${url}/api/uploaded`, requestOptions);
    // console.log("testinggg", await fetch("/"));
    const data = await response.json();

    if (!response.ok) {
        console.log("you got an error")
        console.log(response)
        props.setLoggedIn(false);
        // props.setLoggedIn(false);
        // setErrorMessage(data.detail);
    } else {
        console.log(data);
        // setXRayFlag(arr => []);
        const temp = [];
        Object.entries(data).map(([k, v]) => {
          if(v===1) {
            // setXRayFlag(arr => [...arr, k]);
            temp.push(k);
            // console.log(xRayFlag.length);
          }
        })
        // console.log(xRayFlag.length)
        if(temp.length) {
          setFootText("Abnormalities Detected")
          setBorderColor("border-red-500")
          setFooterBackgroundColor("bg-red-500")
        }
        else {
          setFootText("No Abnormalities Detected")
          setBorderColor("border-green-500")
          setFooterBackgroundColor("bg-green-500")
        }
        setXRayFlag(temp)
    }
    props.setIsLoading(false);
  };

  const getImages = async () => {
    
    try {
      var access_token = getAccessToken();
    } catch (error) {
      console.log(error);
      props.setLoggedIn(false);
    }
    const requestOptions = {
      method: "GET",
      headers: {
        'token': `${access_token}`
      },
      redirect: 'follow'
    };
    // console.log(UserName)
    const response = await fetch(`${url}/api/get_urls?project=TELERADIOLOGY`, requestOptions);
    // console.log("testinggg", await fetch("/"));
    const data = await response.json();
    if(!response.ok) {
      console.log("got an error");
      props.setLoggedIn(false);
    }
    else {
      console.log(data);
      setImageData(data);
    }
  }

  useEffect(() => {
    setFootText("No Image Selected");
    setBorderColor("border-gray-300");
    setFooterBackgroundColor("bg-gray-300");
    setXRayFlag(arr => []);
    getResult();
  }, [image])

  function handleClick() {
    setFootText("No Image Selected");
    setBorderColor("border-gray-300");
    setFooterBackgroundColor("bg-gray-300");
    setXRayFlag(arr => []);
  }

  useEffect(() => {
    getImages();
  }, [])

  return (
    <div>
        <NavBar />
        <Header
            name = {props.name}
            triggerFunction = {props.triggerFunction}
            color={"bg-green-100"}
            heading = "e-Health AI Unit"
        />
        <div className='grid grid-cols-5 px-16 gap-2'>
            <div className='col-span-2 h-full'>
              <ImageBox
                data={imageData}
                color={"bg-green-100"}
                setImage={setImage}
                footText={footText}
                borderColor={borderColor}
                footerBackgroudColor={footerBackgroudColor}
                image={image}
                text={text}
                setText={setText}
                setIsLoading = {props.setIsLoading}
                handleClick = {handleClick}
              />
            </div>
            <div className='col-span-3'>
              <div className='text-md font-light my-4'>
                An AI-based computer vision solution to detect 17 radiological features in chest X-Rays.
              </div>
              <div className='grid grid-cols-3 bg-gray-100 shadow-md rounded gap-5 p-4 mt-4 pb-5'>
                  {matrix}
              </div>
            </div>
        </div>
    </div>
  )
}

export default Teleradiology
