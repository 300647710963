import React, { useState } from 'react'
import defaultImage from "../../svg-icons/default-image.svg"
import { image_data } from '../../data/Pest';
import remove from "../../svg-icons/remove.svg"
import ImageSelectModal from './ImageSelectModal';

function ImageSection({index,image,setImage,imageState,image_data}) {
    
    const [imageModal, setImageModal] = useState(false);
    
    return (
        <div>
            <div className=' bg-white rounded-lg'>
                {image===defaultImage ? (
                    <div className='grid place-items-center gap-6 p-8'>
                        <img className='' src={defaultImage} alt="default" />
                        <p className='text-gray-500 font-normal text-lg'>Upload Image {index}</p>
                        <button
                              className={`border-pest border-2 flex items-center justify-center hover:bg-gray-200 text-pest font-bold py-2 px-16 rounded-xl`}
                              onClick={() => setImageModal(true)}
                            >
                              <div className=''>Select Image</div>
                            </button>
                    </div>
                ): (
                    <div className='grid place-items-center bg-gray-100'>
                        <div className='relative'>
                            <img className={`${imageState==='pass' ? 'border-green-400 border-4' : imageState==='fail' ? 'border-red-400 border-4': ''} rounded-lg h-60 `} src={image} alt="selected image"/>
                            <div className='absolute top-0 right-0 bg-gray-100 rounded-xl m-2 hover:bg-gray-200' onClick={() => setImageModal(true)}>
                                <img className='p-1 h-6' src={remove} alt='remove'/>
                            </div>
                        </div>
                    </div>
                )}
            </div> 
            {imageModal && image_data && (
                <ImageSelectModal
                    index = {index}
                    setImage = {setImage}
                    image_data = {image_data}
                    setImageModal = {setImageModal}
                />
            )}
        </div>
    )
}

export default ImageSection
