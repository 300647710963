import React, { useState } from 'react';

function Model(props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
        <div className='flex cursor-pointer text-2xl text-gray-600 m-1 rounded shadow-lg' onClick={() => setShowModal(true)}>
            <div className={`${props.color} w-1/4 p-4 flex justify-center rounded`} >
                <img  src={props.icon} alt={props.color}/>
            </div>
            <div className='w-2/3 grid place-items-center pt-4 pl-4'>
                {props.name}
            </div>
        </div>
        {showModal && (
            <div className="absolute inset-0 h-full w-full bg-gray-900 opacity-95 flex items-center justify-center shadow-lg" onClick={() => setShowModal(false)}>
                <div className="bg-white p-10 rounded-lg" onClick={(e) => e.stopPropagation()}>
                    <div className="text-2xl font-medium text-center mb-4">What would you like to experience</div>
                    <div className='flex justify-between'>
                    <button
                      className={`${props.border} ${props.text} border-2 flex items-center justify-center hover:bg-gray-200 text-white font-bold py-2 px-16 rounded`}
                      onClick={()=> window.open(props.video, "_blank")}
                    >
                      <div className='mt-2'>Video</div>
                    </button>
                    <button
                      className={`${props.border} ${props.text} border-2 flex items-center justify-center hover:bg-gray-200 text-white font-bold py-2 px-12 rounded`}
                      onClick={()=> window.open(props.app, "_blank")}
                    >
                      <div className='mt-2'>{props.description}</div>
                    </button>
                    </div>
                </div>
            </div>
        )}
    </div>
  );
}

export default Model;
