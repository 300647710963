import React from 'react'
import indianG from "../svg-icons/indian-govern.svg"
import g20 from "../svg-icons/g-20.svg"
import wadhwani from "../svg-icons/wadhwani.svg"

function NavBar() {
  return (
    <div className='flex justify-center px-16 pt-4 pb-4 sm:flex-row h-[150px]'>
      <img className='w-full h-auto sm:w-auto' src={wadhwani} alt='g-20' />
    </div>
  )
}

export default NavBar
