import React, { useState } from 'react'
import derma from "../svg-icons/dermatology.svg"
import tele from "../svg-icons/teleradiology.svg"
import reti from "../svg-icons/retinopathy.svg"
import Button from './Button'
import Dermatology from './Dermatology'
import Retinopathy from './Retinopathy'
import Teleradiology from './Teleradiology'
import NavBar from './NavBar'
import media from '../svg-icons/mediaSurveillance.svg'
import cdss from '../svg-icons/cdss.svg'
import cough from '../svg-icons/cough.svg'
import tbLoss from '../svg-icons/tbLoss.svg'
import anthro from '../svg-icons/anthro.svg'
import tlbs from '../svg-icons/tlbs.svg'
import SecondaryButton from './SecondaryButton'
import Login from './Login'
import CDSS from './CDSS'
import Model from './Model'
import BaseHeader from './BaseHeader'

const Home = (props) => {

    const [homePage, setHomePage] = useState(true);
    const [retiPage, setRetiPage] = useState(false);
    const [telePage, setTelePage] = useState(false);
    const [dermaPage, setDermaPage] = useState(false);

    const handleReti = () => {
        setRetiPage(!retiPage);
        setHomePage(!homePage);
    }

    const handleDerma = () => {
        setDermaPage(!dermaPage);
        setHomePage(!homePage);
    }

    const handleTeli = () => {
        setTelePage(!telePage);
        setHomePage(!homePage)
    }

    return (
            
        <>
                {homePage ? (
                <div className="min-w-full min-h-full place-items-center font-medium text-center font-Gotham">
                    <BaseHeader
                        triggerFunction={props.triggerFunction}
                    />
                    {/* <div className='text-gray-600 text-5xl px-16 mt-12'> Select Solution </div> */}
                    <div className='grid grid-cols-3 mt-12 gap-4 sm:grid-cols-1 lg:grid-cols-3 px-16'>
                        <Button
                            icon = {reti}
                            name = "Diabetic Retinopathy"
                            triggerFunction = {handleReti}
                            color = "bg-reti"
                        />
                        <Button
                            icon = {tele}
                            name = "Teleradiology"
                            triggerFunction = {handleTeli}
                            color = "bg-tele"
                        />
                        <Button
                            icon = {derma}
                            name = "Dermatology"
                            triggerFunction = {handleDerma}
                            color = "bg-derma"
                        />
                        <SecondaryButton
                            icon = {media}
                            name = "Media Surveillance"
                            color = "bg-media"
                            url = "https://ihiplearning.in/idsp/#!/mediascanreport"
                        />
                        <CDSS
                            icon = {cdss}
                            name = "Clinical Decision Support System"
                            color = "bg-cdss"
                            setLoggedIn = {props.setLoggedIn}
                        />
                        <SecondaryButton
                            icon = {anthro}
                            name = "Anthropometry"
                            color = "bg-anthro"
                            url = "https://drive.google.com/file/d/19qYiVoruWVugvcbOqpcmnLAaRxHup-zz/view?usp=sharing"
                        />
                        {/* <SecondaryButton
                            icon = {cough}
                            name = "Cough for TB"
                            color = "bg-cough"
                            url = "example://wiai?app=org.wadhwaniai.tbselfcheckdc"
                        /> */}
                        <Model
                            icon = {cough}
                            name = "Cough Against TB"
                            color = "bg-cough"
                            app = "https://main.dhkk7w6pc03nw.amplifyapp.com/"
                            video = "https://drive.google.com/file/d/12MyYURRKxvfwmkkQsNetPvBU5d53RntI/view?usp=share_link"
                            border = "border-cough"
                            text = "text-cough"
                            hover = "hover: bg-cough"
                            description = "Demo"
                        />
                        <SecondaryButton
                            icon = {tbLoss}
                            name = "TB Loss to Follow-up"
                            color = "bg-tbloss"
                            url = "https://tb-clamp.wadhwaniai.org/login"
                        />
                        <Model
                            icon = {tlbs}
                            name = "TB Line Probe Assay"
                            color = "bg-tbls"
                            app = "https://tb-lpa-preprod.wadhwaniai.org/dashboard/demo"
                            video = "https://drive.google.com/file/d/1NOuOe1Z4giZ2Y47Djx-aIaWmNqLiRkkp/view?resourcekey"
                            border = "border-tbls"
                            text = "text-tbls"
                            description = "Demo"
                        />
                        {/* <SecondaryButton
                            icon = {tlbs}
                            name = "TB Line Probe Assay"
                            color = "bg-tbls"
                            url = "https://tb-lpa-preprod.wadhwaniai.org/dashboard/demo"
                        /> */}
                    </div>
                </div>
            ): (
                <>
                    {dermaPage ? (
                        <Dermatology
                            name="Dermatology"
                            triggerFunction={handleDerma}
                            setLoggedIn = {props.setLoggedIn}
                            setIsLoading = {props.setIsLoading}
                        />
                    ) : (
                        <>
                            {
                                telePage ? (
                                    <Teleradiology
                                        name = "Teleradiology"
                                        triggerFunction = {handleTeli}
                                        setLoggedIn = {props.setLoggedIn}
                                        setIsLoading = {props.setIsLoading}
                                    />
                                ) : (
                                    <>
                                        {
                                            retiPage ? (
                                                <Retinopathy
                                                    name = "Diabetic Retinopathy"
                                                    triggerFunction = {handleReti}
                                                    setLoggedIn = {props.setLoggedIn}
                                                    setIsLoading = {props.setIsLoading}
                                                />
                                            ) : (
                                                <> this should never be shown ideally</>
                                            )
                                        }
                                    </>
                                )
                            }
                        </>
                    )}
                </>
            )}
        </>

    
    )
}

export default Home
