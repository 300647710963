import React, { useState } from "react";
import { video_data } from "../data/Pest";
import Button from "./Button";
import pest from '../svg-icons/pest.svg';
import dfd from '../svg-icons/dfd.svg';
import kkms from '../svg-icons/kkms.svg';
import ianm from '../svg-icons/ianm.svg';

function PostModal(props) {
  const [showModal, setShowModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);

  const handlePM = () => {
   setShowModal(true)
  };

  const handleKKMS = () => {
    // window.open('https://demos-agri.wadhwaniai.org/kkms', '_blank')
    window.location.href = 'https://demos-agri.wadhwaniai.org/kkms';
  };

  const handleDfd = () => {
    // window.open('http://localhost:3001/ai_collect', '_blank')
    window.location.href = 'https://demos-agri.wadhwaniai.org/ai_collect';
  };

  const handleIanm = () => {
    // window.open('https://ianm-dev.wadhwaniai.org', '_blank')
    window.location.href = 'https://ianm-dev.wadhwaniai.org';
  };
  const handleNpss = () => {
    // window.open('https://demos-agri.wadhwaniai.org/npss', '_blank')
    window.location.href = 'https://demos-agri.wadhwaniai.org/npss';
  };

  const handlePmkns = () => {
    // window.open('https://demos-agri.wadhwaniai.org/npss', '_blank')
    window.location.href = 'https://demos-agri.wadhwaniai.org/pmks';
  };

  const handleVideoModal = () => {
    setShowModal(!showModal);
    setVideoModal(!videoModal);
  };

  const matrix = video_data.map((video) => {
    return (
      <div
        className="flex cursor-pointer text-xl text-gray-600 m-1 rounded shadow-lg hover:bg-gray-200"
        onClick={() => window.open(video.url, "_blank")}
      >
        <div className={`w-1/4 flex justify-center rounded`}>
          <img src={video.image} alt={video.title} />
        </div>
        <div className="w-2/3 grid place-items-center pt-4 pl-4">
          {video.title}
        </div>
      </div>
    );
  });

  return (
    <div className="min-w-full min-h-full place-items-center font-medium text-center font-Gotham">
     <div className='grid grid-cols-3 mt-12 gap-4 sm:grid-cols-1 lg:grid-cols-3 px-16' style={{marginTop:"50px",marginBottom:"50px"}}>
        <Button
          icon={pest}
          name="Pest Management"
          triggerFunction={handlePM}
          color="bg-pm"
        />
        <Button
          icon={dfd}
          name="Digital Farmer Diary"
          triggerFunction={handleDfd}
          color="bg-dfd"
        />
        <Button
          icon={kkms}
          name="Kisan Knowledge Management System"
          triggerFunction={handleKKMS}
          color="bg-kkms"
        />
        <Button
          icon={ianm}
          name="Integrated Agriculture News Monitoring"
          triggerFunction={handleIanm}
          color="bg-ianm"
        />
         <Button
          icon={pest}
          name="National Pest Surveillance System"
          triggerFunction={handleNpss}
          color="bg-npss"
        />
        <Button
          icon={kkms}
          name="Pradhan Mantri kisan Samman Nidhi"
          triggerFunction={handlePmkns}
          color="bg-kkms"
        />
      </div>
      {/* <div className='flex cursor-pointer text-2xl text-gray-600 m-1 rounded shadow-lg' onClick={() => setShowModal(true)}>
                <div className={`${props.color} w-1/4 p-4 flex justify-center rounded`} >
                    <img src={props.icon} alt="something"/>
                </div>
                <div className='w-2/3 grid place-items-center pt-4 pl-4'>
                    {props.name}
                </div>
            </div> */}
      {showModal && (
        <div
          className="absolute inset-0 h-full w-full bg-gray-900 opacity-95 flex items-center justify-center shadow-lg"
          onClick={() => setShowModal(false)}
        >
          <div
            className="bg-white p-10 rounded-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="text-2xl font-medium text-center mb-4">
              What would you like to experience?
            </div>
            <div className="flex justify-between">
              <button
                className={`${props.border} ${props.text} border-2 flex items-center justify-center hover:bg-gray-200 font-bold py-2 px-16 rounded`}
                onClick={handleVideoModal}
              >
                <div className="mt-2">Videos</div>
              </button>
              <button
                className={`${props.border} text-pest border-2 flex items-center justify-center hover:bg-gray-200 font-bold py-2 px-12 rounded`}
                onClick={props.triggerFunction}
              >
                <div className="mt-2">Demo</div>
              </button>
            </div>
          </div>
        </div>
      )}
      {videoModal && (
        <div
          className="absolute inset-0 h-full w-full bg-gray-900 opacity-95 flex items-center justify-center shadow-lg"
          onClick={handleVideoModal}
        >
          <div
            className="bg-white p-10 rounded-lg w-3/4"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2 text-2xl text-gray-600 m-1">
                {" "}
                Videos{" "}
              </div>
              {matrix}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PostModal;
