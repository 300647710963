import React from 'react'

function Button(props) {
  return (
    <div className='flex cursor-pointer text-2xl text-gray-600 m-1 rounded shadow-lg' onClick={props.triggerFunction}>
        <div className={`${props.color} w-1/4 p-4 flex justify-center rounded`} >
            <img  src={props.icon} alt={props.color}/>
        </div>
        <div className='w-2/3 grid place-items-center pt-4 pl-4'>
            {props.name}
        </div>
    </div>
  )
}

export default Button